<template>
  <div class="login-page">
    <div class="header">
        <div class="logo">
        <img src="@/assets/img/logo-new.png" alt="" width="249px" height="26px">
      </div>
    </div>
    <div class="content" >
      <div class="login-left"  >
        <img src="@/assets/img/login-left.png" alt="" class="left-icon" >
      </div>
      <div  class="login-right" >
        <img src="@/assets/img/login-title.png" alt="" width="466" height="64" class="login-title">
        <el-form :model="myForm" :rules="rules" ref="myForm" label-width="80px">     
          <el-form-item label="" prop="name" class="login-form">
            <el-input v-model="myForm.name" clearable>
              <span slot="prefix" class="icon-user"></span>
            </el-input>
          </el-form-item>
          <el-form-item label="" prop="password" class="login-form eye" :class="isChoose ? 'focus' : ''">
            <el-input v-model="myForm.password" :type="typePassword" ref="passwordRef" clearable class="show-hide-pws">
              <span slot="prefix" class="icon-user password "></span>
              <span slot="suffix" class="icon-eye" :class="!showEye? 'close':''" 
               @mousedown="eyeMousedown" @mouseup="isShowPassword"></span>
            </el-input>
          </el-form-item>
          <el-form-item >
            <div class="login-btn" @click="submitForm('myForm')">
              <el-button   type="primary" >登  录</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="login-footer">
      Copyright ©2022 虚假信息智能制作平台 All Rights Reserved
    </div>
  </div>
</template>

<script>
import { LOGIN } from '@/remote';
import {STORAGE} from '@/utils/const';

export default {
  name: "login",
  components: {},
  data() {
    return {
      myForm: {
        name: '',
        password: '',
      },
      typePassword:'password',
      showEye:true,
      clearUser:false,
      clearPws:false,
      clearPwsTwo:false,
      rules: {
        name: [
          { required: true, message: '请输入账号', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
      },
      isChoose: false,
    };
  },

  mounted() {
  },
  computed: {
  },

  methods: {
    eyeMousedown() {
      this.isChoose = true;
    },
    isShowPassword(){
      this.isChoose = false;
      this.showEye = !this.showEye;
      this.typePassword = this.showEye?'password':'text';
      this.$refs.passwordRef.focus();
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let user = this.myForm.name.trim();
          let password = this.myForm.password.trim();
          if(user.length == 0|| password.length == 0){
            this.$message({
              type: 'warning',
              message: '用户名或密码不能为空',
            });
            return;
          }
          let params={
            username: user,
            password: password
          }
          LOGIN.login(params).then((res)=>{
            if(res.code == 200){
              let {token = "",expireDate = '', username = ''}=res.data
              localStorage.setItem(STORAGE.RJ_SPECIAL_TOKEN, token);
              localStorage.setItem(STORAGE.RJ_SPECIAL_USER_INFO, JSON.stringify({expireDate, username}));
              this.$router.replace(
                {
                  path:this.$route.query.redirect?this.$route.query.redirect: "/",
                }
              )
            }
          }).catch((error)=>{
            console.log(error);
          })
        }
      });
    },
  },
};
</script>

<style lang="less">
  @import "../../assets/less/login";
</style>
